import {Layout} from '../../components/layout';
import * as styles from './index.module.css';

const AnalyticsWEPage = () => (
    <Layout title="Analytics (APAC)" scope="analytics.apac">
        <div className={styles.wrapper}>
            <iframe
                title="APAC YT Quarterly Performace Dashboard_v4"
                width="100%"
                height="100%"
                src="https://app.powerbi.com/reportEmbed?reportId=c275d272-6bfd-4ee9-8ab2-339f48063078&autoAuth=true&ctid=ae087f2d-8a82-46dd-a538-38bebd294479"
                style={{outline: '0', border: 'none'}}
            >
            </iframe>
        </div>
    </Layout>
);

export default AnalyticsWEPage;
